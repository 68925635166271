import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SearchResultsComponent from '../../../pages/search/components/SearchResults';
import styles from './modalSearchResults.module.scss';

const StyledModal = withStyles({
    root: {
        top: '70px !important',
    },
})(Modal);

const ModalSearchResult = ({
    open,
    filters,
    onCloseModal,
    noLetters = false,
    urlPrefix,
}) => {
    return (
        <StyledModal
            open={open}
            hideBackdrop={true}
            disablePortal={true}
            disableEnforceFocus={true}
            disableEscapeKeyDown={true}
            onBackdropClick={onCloseModal}
        >
            <ClickAwayListener onClickAway={onCloseModal}>
                <div className={styles.modalContainer}>
                    <div className={styles.innerWrapper}>
                        <SearchResultsComponent
                            filters={filters}
                            closeResults={onCloseModal}
                            noLetters={noLetters}
                            urlPrefix={urlPrefix}
                        />
                    </div>
                </div>
            </ClickAwayListener>
        </StyledModal>
    );
};

ModalSearchResult.propTypes = {
    open: PropTypes.bool.isRequired,
};

ModalSearchResult.defaultProps = {
    open: false,
};

export default ModalSearchResult;
