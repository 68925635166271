import React from 'react';
import ResultItemComponent from '../ResultItem';
import styles from './resultGroup.module.scss';

const ResultGroupComponent = ({
    title,
    items,
    itemIcon,
    itemTitle,
    subtitleRenderer,
    total,
    highlightText,
    linkGenerate,
    loadMore,
    closeResults,
}) => {
    const onClickMore = () => {
        loadMore && loadMore();
    };

    return (
        <div className={styles.resultGroup}>
            <div className={styles.header}>
                <span className={styles.title}>{title}</span>
                <div className={styles.line} />
            </div>
            <div className={styles.list}>
                {items.map((item, i) => (
                    <ResultItemComponent
                        key={i}
                        title={
                            !itemTitle
                                ? ''
                                : typeof itemTitle === 'string'
                                ? item[itemTitle]
                                : itemTitle(item)
                        }
                        subtitle={
                            !subtitleRenderer
                                ? ''
                                : typeof subtitleRenderer === 'string'
                                ? item[subtitleRenderer]
                                : subtitleRenderer(item)
                        }
                        snippet={item?.snippet || ''}
                        itemIcon={itemIcon}
                        highlightText={highlightText}
                        link={linkGenerate && linkGenerate(item)}
                        closeResults={closeResults}
                    />
                ))}
            </div>
            {items.length < total && (
                <span className={styles.loadMore} onClick={onClickMore}>
                    Показать ещё
                </span>
            )}
        </div>
    );
};

export default ResultGroupComponent;
