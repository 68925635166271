import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import styles from './resultItem.module.scss';

const ResultItemComponent = ({
    title,
    subtitle,
    itemIcon,
    highlightText,
    link,
    snippet,
    closeResults,
}) => {
    const parser = new DOMParser();
    const d = snippet && parser.parseFromString(snippet, 'text/html');
    const clearedText = d?.body?.textContent || d?.body?.innerText;

    const onClick = () => {
        // closeResults && closeResults();
        window.open(link, '_blank');
    };

    return (
        <div className={styles.item} onClick={onClick}>
            <div className={styles.icon}>{itemIcon}</div>
            <div className={styles.data}>
                <div className={styles.title}>
                    <Highlighter
                        searchWords={[highlightText]}
                        autoEscape={true}
                        textToHighlight={title}
                    />
                </div>
                <div className={styles.subtitle}>{subtitle}</div>
                <div className={styles.subtitle}>
                    <Highlighter
                        searchWords={(highlightText || '').split(' ')}
                        autoEscape={true}
                        textToHighlight={clearedText}
                    />
                </div>
            </div>
        </div>
    );
};

ResultItemComponent.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    highlightText: PropTypes.string,
    itemIcon: PropTypes.object,
    closeResults: PropTypes.func,
};

export default ResultItemComponent;
