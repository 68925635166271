import {
    TOGGLE_NAV_BAR,
    ERROR_MESSAGE_UPDATE,
    SUCCESS_MESSAGE_UPDATE,
    WARNING_MESSAGE_UPDATE,
    USER_ENTRANCE_UPDATE,
    TOGGLE_NAV_BAR_CONCEPT,
    TOGGLE_NAV_BAR_PEREK,
} from '../actions/actionTypes';

const initialState = {
    openedNavBar: false,
    openedNavBarConcept: false,
    openedNavBarPerek: false,
    fetching: false,
    errorMessage: false,
    successMessage: false,
    warningMessage: false,
    isFirstLogin: false,
    userName: null,
    userSubscriptions: {},
};

const mainAdminReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TOGGLE_NAV_BAR:
            return {
                ...state,
                openedNavBar: payload ? payload.data : !state.openedNavBar,
            };
        case TOGGLE_NAV_BAR_CONCEPT:
            return {
                ...state,
                openedNavBarConcept: payload
                    ? payload.data
                    : !state.openedNavBarConcept,
            };
        case TOGGLE_NAV_BAR_PEREK:
            return {
                ...state,
                openedNavBarPerek: payload
                    ? payload.data
                    : !state.openedNavBarPerek,
            };
        case ERROR_MESSAGE_UPDATE:
            return {
                ...state,
                errorMessage: payload,
            };
        case SUCCESS_MESSAGE_UPDATE:
            return {
                ...state,
                successMessage: payload,
            };
        case WARNING_MESSAGE_UPDATE:
            return {
                ...state,
                warningMessage: payload,
            };
        case USER_ENTRANCE_UPDATE:
            return {
                ...state,
                isFirstLogin: payload.isFirstLogin ? true : false,
                userName: payload.userName ? payload.userName : null,
                userSubscriptions: payload.userSubscriptions
                    ? payload.userSubscriptions
                    : {},
            };
        default:
            return state;
    }
};

export default mainAdminReducer;
