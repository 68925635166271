import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from 'react-oauth2-code-pkce';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import useWindowSize from '../../../helpers/windowSize';
import { toggleNavBarConcept } from '../../../actions/mainAction';
import { changeFavicon, checkIfTest } from '../../../helpers/m';
import User from '../../../helpers/user';
import { BREAKPOINTS, CONCEPT_ROUTES } from '../../../helpers/constants';
import NotificationIcons from '../../CommonParts/NotificationIcons';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';
import { ReactComponent as LogoImg } from '../../../assets/logoBig.svg';
import HeaderSearchComponent from '../ConceptHeaderSearch';
import MoreMenu from '../../CommonParts/MoreMenu';
import styles from './conceptHeader.module.scss';

const ConceptHeader = () => {
    const { token, logOut } = useContext(AuthContext);
    const isTest = checkIfTest();
    const isLoggedIn = isTest ? User.isLoggedIn() : token;
    const profile = useSelector((state) => state.profileReducer.profile);
    const openedNavBarConcept = useSelector(
        (state) => state.mainAdminReducer.openedNavBarConcept,
    );
    const photo = profile?.photo || '';
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const size = useWindowSize();
    const showMobileMenu =
        openedNavBarConcept && size?.width <= BREAKPOINTS.conceptLaptop;

    const toggleMenu = () => dispatch(toggleNavBarConcept());
    const closeMenu = () => dispatch(toggleNavBarConcept({ data: false }));

    useEffect(() => {
        changeFavicon();
    }, []);

    const onClickLogout = () => {
        User.logout();
        if (!isTest) {
            return logOut().finally(() => window.location.replace('/login'));
        }
        return window.location.replace('/login');
    };

    return (
        <div className={styles.appHeader}>
            <div className={styles.appHeaderInner}>
                <div className={styles.headerFirstBlock}>
                    <div
                        className={styles.headerMenuIconBlock}
                        onClick={() => dispatch(toggleNavBarConcept())}
                    >
                        <MenuIcon className={styles.headerMenuIcon} />
                    </div>
                    <Link to="/concept" className={styles.headerLogoBlock}>
                        <LogoImg className={styles.headerLogo} />
                    </Link>
                </div>
                {isLoggedIn && (
                    <>
                        <div className={styles.headerNavbar}>
                            {CONCEPT_ROUTES.map((item) => (
                                <Link
                                    key={item.url}
                                    to={item.url}
                                    className={styles.headerLink}
                                >
                                    {item.title}
                                    {location?.pathname === item.url && (
                                        <div
                                            className={styles.headerLinkActive}
                                        />
                                    )}
                                </Link>
                            ))}
                            <HeaderSearchComponent />
                        </div>
                        <div className={styles.headerSecondBlock}>
                            <div className={styles.notifyIconsBlock}>
                                <NotificationIcons isWhite />
                            </div>
                            <div className={styles.profileBlock}>
                                <MoreMenu
                                    items={[
                                        {
                                            name: 'Профиль',
                                            onClick: () =>
                                                history.push('/profile'),
                                        },
                                        {
                                            name: 'Выйти',
                                            onClick: onClickLogout,
                                        },
                                    ]}
                                    customIcon={
                                        photo ? (
                                            <img
                                                src={photo}
                                                className={styles.photo}
                                                alt="profile"
                                            />
                                        ) : (
                                            <div className={styles.photo} />
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            {showMobileMenu && (
                <SwipeableDrawer
                    anchor="left"
                    open={!!openedNavBarConcept}
                    onClose={closeMenu}
                    onOpen={toggleMenu}
                >
                    <div className={styles.navBarSmall}>
                        <div className={styles.navBarHeader}>
                            <Link
                                to="/concept"
                                onClick={() => dispatch(toggleNavBarConcept())}
                            >
                                <LogoImg className={styles.navBarLogo} />
                            </Link>
                            <MenuIcon
                                className={styles.navBarIcon}
                                onClick={() => dispatch(toggleNavBarConcept())}
                            />
                        </div>
                        {CONCEPT_ROUTES.map((item) => (
                            <Link
                                key={item.url}
                                to={item.url}
                                className={styles.navBarLink}
                                onClick={() => dispatch(toggleNavBarConcept())}
                            >
                                {item.title}
                            </Link>
                        ))}
                    </div>
                </SwipeableDrawer>
            )}
        </div>
    );
};

export default ConceptHeader;
