import {
    ATTACHMENT_COLORS_CLASSNAME,
    PAGE_SIZE,
    UTC_OFFSET,
    TEST_HOST,
    LOCAL_HOST,
    ARTICLE_STATUSES_DATA,
    LETTER_STATUSES_DATA,
} from './constants';
import { getFromStorage, removeFromStorage } from './storage';
import moment from 'moment';
import 'moment/locale/ru';

export const calcPageCount = (rows = 0, pageSize = PAGE_SIZE) => {
    const row = rows > 0 ? rows : 1;
    const pageCount = Math.ceil(row / pageSize);
    return pageCount > 0 ? pageCount : 1;
};

export const buildQueryString = (obj) => {
    if (!obj) {
        return '';
    }
    let url = '?';
    const objKeys = Object.keys(obj);
    objKeys.forEach((it) => {
        url += `${it}=${encodeURIComponent(obj[it])}&`;
    });
    return url;
};

export const toLocalDate = (data) => {
    const date = data || new Date();
    return new Date(date).toLocaleDateString('ru');
};

export const toLocalDateFullMonth = (data) => {
    const date = data || new Date();
    return moment(date).utcOffset(UTC_OFFSET).format('DD MMMM YYYY');
};

export const toMessageDate = (data) => {
    const date = data || new Date();
    return moment(date).utcOffset(UTC_OFFSET).format('DD.MM.YYYY');
};

export const toMessageDateTime = (data) => {
    const date = data || new Date();
    return moment(date).utcOffset(UTC_OFFSET).format('DD.MM.YYYY HH:mm');
};

export const toMessageTime = (data) => {
    const date = data || new Date();
    return moment(date).utcOffset(UTC_OFFSET).format('HH.mm');
};

export const toApprovalTime = (data) => {
    const date = data || new Date();
    return moment(date).utcOffset(UTC_OFFSET).format('HH:mm');
};

export const scrollToTop = () => {
    window &&
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
};

export const getFirstDayBegin = (date = new Date(), isYear = false) => {
    let lastDay = moment(date);
    if (isYear) {
        lastDay = lastDay.startOf('year');
    }
    lastDay = lastDay.startOf('month').startOf('day').format('YYYY-MM-DD');
    return `${lastDay}`;
};

export const getLastDayEnd = (date = new Date(), isYear = false) => {
    let lastDay = moment(date);
    if (isYear) {
        lastDay.endOf('year');
    }
    lastDay = lastDay.endOf('month').endOf('day').format('YYYY-MM-DD');
    return `${lastDay}`;
};

export const toFilterDate = (date) => {
    if (!date) return '';
    return moment(date).utcOffset(UTC_OFFSET).format('YYYY-MM-DD');
};

export const transformDateForTree = (data = []) => {
    let arr = [...data];
    let o = {};

    const getUniqId = (str) => {
        return Number(str) + 100000000;
    };

    const loop = (data) => {
        return data.map((item) => {
            if (item.children) {
                item.children = Object.values(item.children);
                if (item.children.length > 0) {
                    loop(item.children);
                }
            } else {
                item.fakeDir = true;
            }
            return item;
        });
    };

    arr.forEach((el, idx) => {
        const { date } = el;
        if (!el || !date) {
            return;
        }
        const dates = date.split('-');
        if (!o[dates[0]]) {
            o[dates[0]] = {
                id: getUniqId(dates[0]),
                title: `${dates[0]}`,
                fromDate: getFirstDayBegin(date, true),
                toDate: getLastDayEnd(date, true),
                children: {},
            };
        }
        if (!o[dates[0]].children[dates[1]]) {
            let t = moment(date).format('MMMM');
            t = t.charAt(0).toUpperCase() + t.slice(1);
            o[dates[0]].children[dates[1]] = {
                id: getUniqId(`${dates[0]}${dates[1]}`),
                title: t,
                fromDate: getFirstDayBegin(date),
                toDate: getLastDayEnd(date),
                otherParents: [getUniqId(dates[0])],
                children: {},
                preselected: idx === 0,
            };
        }
        if (!o[dates[0]].children[dates[1]].children[dates[2]]) {
            const firstParent = getUniqId(dates[0]);
            const secondParent = getUniqId(`${dates[0]}${dates[1]}`);
            o[dates[0]].children[dates[1]].children[dates[2]] = {
                id: getUniqId(dates.join('')),
                title: dates.reverse().join('.'),
                fromDate: date,
                toDate: date,
                otherParents: [firstParent, secondParent],
                planPublicDate: date,
            };
        }
    });
    return loop(Object.values(o));
};

export const updateUrl = (url = '') => {
    return window.history.replaceState(null, 'Инфоцентр', url);
};

export const getAttachmentColor = (ext = 'pdf') => {
    const orangeColorArr = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf'];
    const color = orangeColorArr.includes(ext) ? 'orange' : 'blue';
    return ATTACHMENT_COLORS_CLASSNAME[color];
};

export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const sortAttachments = (item) => {
    if (!item) return {};
    if (item.attachments && item.attachments.length < 1) return item;
    item.attachments = item.attachments.sort(
        (a, b) => a.order_num - b.order_num,
    );
    return item;
};

export const dataForCheckboxTree = (data = [], parentId = null) => {
    const arr = [...data];

    const localLoop = (data, parentId) => {
        return data.map((item) => {
            item.value = item.id;
            item.label = item.name;
            item.parent = parentId || null;
            if (item.children) {
                localLoop(item.children, item.value);
            } else {
                const lId = `lId-${
                    Math.random() * 1000000 + new Date().getTime()
                }`;
                item.children = [{ value: lId, label: '', leaf: true }];
            }
            if (item.type === 'market') {
                if (item.distribution_channel_code) {
                    item.label += ` (${item.distribution_channel_code})`;
                }
                item.children = null;
            }
            return item;
        });
    };

    return localLoop(arr, parentId);
};

export const flatChildrenArray = (data = [], onlyChildren) => {
    let arr = [];

    const loop = (data) => {
        return data.forEach((item) => {
            if (!onlyChildren) {
                arr.push(item);
            }
            if (item.children) {
                loop(item.children);
            } else if (item.item && item.item.children) {
                loop(item.item.children);
            } else if (onlyChildren) {
                arr.push(item);
            }
        });
    };

    loop(data);

    return arr;
};

export const checkIfTest = () => {
    const { host } = window.location;
    const testHost = host && host.indexOf(TEST_HOST) > -1;
    const localHost = host && host.indexOf(LOCAL_HOST) > -1;
    if (host.includes('localhost') || process.env.NODE_ENV === 'development') {
        return true;
    }
    return testHost || localHost;
};

export const sortArrayByKey = (data = [], key = 'id', isAsc = true) => {
    let newArr = data;

    newArr = newArr.sort((a, b) => {
        return isAsc ? a[key] - b[key] : b[key] - a[key];
    });

    newArr.forEach((item) => {
        if (item.children && item.children[0]) {
            sortArrayByKey(item.children, key, isAsc);
        }
    });

    return newArr;
};

export const dataForCheckboxTreeSimple = (data = []) => {
    const arr = [...data];

    const loop = (data, parentId) => {
        return data.map((item) => {
            item.value = item.id;
            item.parentId = parentId;
            item.label = item.data?.name ? item.data?.name : item.name;
            if (item.children) {
                loop(item.children, item.id);
            }
            return item;
        });
    };

    return loop(arr);
};

export const getRestCommentText = (restCount = 0) => {
    let str;
    switch (restCount) {
        case 1:
            str = `Еще ${restCount} комментарий`;
            break;
        case 2:
        case 3:
        case 4:
            str = `Еще ${restCount} комментария`;
            break;
        default:
            str = `Еще ${restCount} комментариев`;
    }
    return str;
};

export const getStatusAction = (status = '', id) => {
    if (!status) return {};
    if (!ARTICLE_STATUSES_DATA[status]) return {};
    const obj = { ...ARTICLE_STATUSES_DATA[status] };
    obj.url = `${id}/${obj.url}`;
    return obj;
};

export const getLetterStatusAction = (status = '', id) => {
    if (!status) return {};
    if (!LETTER_STATUSES_DATA[status]) return {};
    const obj = { ...LETTER_STATUSES_DATA[status] };
    obj.url = `${id}/${obj.url}`;
    return obj;
};

export const checkSavedUrl = () => {
    const userTriedUrl = getFromStorage('triedUrl');
    if (userTriedUrl) {
        removeFromStorage('triedUrl');
        window.location.replace(userTriedUrl);
        return true;
    }
    return false;
};

export const sortOrderArr = (data = []) => {
    return data.sort((a, b) => a.sort_order - b.sort_order);
};

export const transformExcelToArray = (str = '') => {
    let newList = str
        .replaceAll(',', ' ')
        .replaceAll(';', ' ')
        .replaceAll('\n', ' ');
    newList = newList.split(' ').filter((x) => x !== '' && x !== ' ');
    return [...new Set([...newList])];
};

export const parseComment = (str = '') =>
    str
        .split(' ')
        .map((el) => {
            if (
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
                    el,
                )
            ) {
                return `<a target="_blank" href="${el}">${el}</a>`;
            }
            return el;
        })
        .join(' ');

export const addScript = (SCRIPT_ID, SCRIPT_URL_ID) => {
    const script = document.createElement('script');
    script.src = `https://rpabot.x5.ru/embed/embed.js?id=${SCRIPT_URL_ID}`;
    script.async = true;
    script.id = SCRIPT_ID;
    document.body.appendChild(script);
};

export const removeScript = (SCRIPT_ID) => {
    document.getElementById(SCRIPT_ID)?.remove();
    document.getElementById('widget-root')?.remove();
    document
        .querySelectorAll('[data-origin="x5Custom"]')
        .forEach((e) => e.remove());
    document
        .querySelectorAll('[data-origin="justwidget"]')
        .forEach((e) => e.remove());
    [...document.getElementsByTagName('script')].forEach((el) =>
        el.src?.includes('rpabot.x5') || el.src?.includes('matomo.x5')
            ? el.remove()
            : el,
    );
    [...document.getElementsByTagName('link')].forEach((el) =>
        el.src?.includes('rpabot.x5') || el.src?.includes('matomo.x5')
            ? el.remove()
            : el,
    );
};

export const transformEmails = (list) => {
    let newList = [...new Set([...list])];
    newList = newList
        .join(' ')
        .replaceAll(',', ' ')
        .replaceAll(';', ' ')
        .replaceAll('\n', ' ');
    newList = newList.split(' ').filter((x) => x !== '' && x !== ' ');
    return [...new Set([...newList])];
};

export const formatEmails = (data) => {
    let arr = data.split(/\r?\n?\s/);
    arr = arr.filter((el) => el?.includes('@'));
    arr = arr.join(' ');
    arr = arr.replaceAll('<', '').replaceAll('>', '');
    arr = arr.split(' ');
    return transformEmails(arr);
};

export const getNoun = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};

export const changeFavicon = (url = '/favicon.ico') => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = url;
};
